import React from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import {Route, Routes} from "react-router-dom";

import Topbar from "./Topbar";
import Home from "./Home";
import Gyufacimkek from "../cimkek/Gyufacimkek";
import CimkeReszletek from "../cimkek/CimkeReszletek";
import SorozatReszletek from "../cimkek/SorozatReszletek";

const Content = ({sidebarIsOpen, toggleSidebar}) => (
    <Container
        fluid
        className={classNames("content", {"is-open": sidebarIsOpen})}
    >
        <Topbar toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen}/>
        <div className="top-margin">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/gyufacimkek" element={<Gyufacimkek/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="/sorozat/:sorozatId/cimke/:cimkeId" element={<CimkeReszletek/>}/>
                <Route path="/sorozat/:sorozatId" element={<SorozatReszletek/>}/>
            </Routes>
        </div>
    </Container>
);

export default Content;
