import React from "react";

function Footer() {
    return (
        <footer className="styled-header">
            <div className="styled-footer"> © Copyright {new Date().getFullYear()}. Scriptum Zrt.</div>
        </footer>
    );
}

export default Footer;