import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft} from "@fortawesome/free-solid-svg-icons";
import {Button, Collapse, Nav, Navbar, NavItem, NavLink,} from "reactstrap";
import {Link} from "react-router-dom";

const Topbar = ({toggleSidebar}) => {
    const [topbarIsOpen] = useState(true);

    return (
        <div className={"topbar"}>
            <Navbar
                light
                className="navbar shadow-sm p-3 styled-header rounded"
                expand="lg"
            >
                <Button className="btn-custom" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faAlignLeft}/>
                </Button>
                <Collapse isOpen={topbarIsOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink tag={Link} to={"/home"}>
                                <h2 className="styled-text"> Magyar Gyufacímke Katalógus
                                </h2>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
};

export default Topbar;
