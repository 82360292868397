import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {Col, Collapse, Form, FormGroup, Input, Label, NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import {useMatchboxContext} from "../cimkek/MatchboxDataContext";
import axios from "axios";
import {properties} from "../../properties";

const SearchMenu = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed(!collapsed);
    const {icon, title} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const {setMatchboxList, filter, setFilter, setIndex} = useMatchboxContext();

    useEffect(() => {
        const setMatchboxData = () => {
            axios.get(properties.baseURL + "/series").then((response) => {
                setMatchboxList(response.data);
            }).catch(error => {
                alert("Error Occurred while loading data:" + error);
            });
        }

        setMatchboxData();
    }, [setMatchboxList]);

    const searchHandler = (addedFilter) => {
        setFilter({...filter, ...addedFilter})
        setIndex(0);
        //Keresés a cíke listában vagy egy sorozat részleteiben lehetséges, máshonnan keresés megadásakor átnavigál a címke listára.
        if (!location.pathname.includes("gyufacimkek") && (!location.pathname.includes("sorozat") || location.pathname.includes("cimke"))) {
            navigate('/gyufacimkek');
        }
    };

    return (
        <div>
            <NavItem
                onClick={toggle}
                className={classNames({"menu-open": !collapsed})}
            >
                <h5>
                    <NavLink className="dropdown-toggle cursor-link">
                        <FontAwesomeIcon icon={icon} className="mr-2"/>
                        {title}
                    </NavLink></h5>
            </NavItem>
            <Collapse
                isOpen={!collapsed}
                navbar
                className={classNames("items-menu", {"mb-1": !collapsed})}
            >
                <Form className="padded-container">
                    <FormGroup row>
                        <Label for="keresesiKifejezes" xs={12}>
                            Keresési kifejezés:
                        </Label>
                        <Col xs={11}>
                            <Input
                                id="keresesiKifejezes"
                                name="keresesiKifejezes"
                                type="text"
                                onChange={(e) => searchHandler({sorozat: e.target.value.toLowerCase()})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="katalogusszam" xs={12}>
                            Katalógusszám:
                        </Label>
                        <Col xs={11}>
                            <Input
                                id="katalogusszam"
                                name="katalogusszam"
                                type="text"
                                onChange={(e) => searchHandler({katalogus: e.target.value.toLowerCase()})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="evszamTol" xs={12}>
                            Évszám:
                        </Label>
                        <Col xs={5}>
                            <Input
                                id="evszamTol"
                                name="evszamTol"
                                type="number"
                                value={filter.evtol ? filter.evtol : ''}
                                onChange={(e) => searchHandler({evtol: e.target.value.toLowerCase()})}
                            />
                        </Col>
                        <Label for="evszamIg" xs={1}>
                            -
                        </Label>
                        <Col xs={5}>
                            <Input
                                id="evszamIg"
                                name="evszamIg"
                                type="number"
                                value={filter.evig ? filter.evig : ''}
                                onChange={(e) => searchHandler({evig: e.target.value.toLowerCase()})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="meret" xs={12}>
                            Méret:
                        </Label>
                        <Col xs={11}>
                            <Input
                                id="meret"
                                name="meret"
                                type="text"
                                onChange={(e) => searchHandler({meret: e.target.value.toLowerCase()})}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </Collapse>
        </div>
    );
};

export default SearchMenu;
