import React, {useState} from "react";
import classNames from "classnames";
import {Collapse, Form, FormGroup, Input, Label, NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMatchboxContext} from "../cimkek/MatchboxDataContext";

const OrderingMenu = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed(!collapsed);
    const {icon, title} = props;
    const {order, setOrder} = useMatchboxContext();

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    const orderChange = event => {
        switch (event.target.value) {
            default:
            case 'Nincs' :
                setOrder({...order, type: undefined});
                break;
            case 'Címke név szerint' :
                setOrder({...order, type: 'cimkeNev'});
                break;
            case 'Katalógusszám szerint' :
                setOrder({...order, type: 'katalogusszam'});
                break;
            case 'Megjelenési év szerint' :
                setOrder({...order, type: 'megjelenes'});
                break;
            case 'Címkék száma szerint' :
                setOrder({...order, type: 'cimkeSzam'});
                break;
        }
        scrollToTop();
    };

    const orderAscendenceChange = event => {
        switch (event.target.value) {
            default:
            case 'Növekvő' :
                setOrder({...order, ascending: true});
                break;
            case 'Csökkenő' :
                setOrder({...order, ascending: false});
                break;
        }
        scrollToTop();
    };

    return (
        <div>
            <NavItem
                onClick={toggle}
                className={classNames({"menu-open": !collapsed})}
            >
                <h5><NavLink className="dropdown-toggle cursor-link">
                    <FontAwesomeIcon icon={icon} className="mr-2"/>
                    {title}
                </NavLink></h5>
            </NavItem>
            <Collapse
                isOpen={!collapsed}
                navbar
                className={classNames("items-menu", {"mb-1": !collapsed})}
            >
                <Form className="padded-container">
                    <Label>
                        Sorrend:
                    </Label>
                    <FormGroup check>
                        <Label className="padding-right-double" check>
                            <Input type="radio" name="radioOption" id="radioOption1" value="Növekvő" defaultChecked
                                   onChange={orderAscendenceChange}
                            />
                            Növekvő
                        </Label>
                        <Label check>
                            <Input type="radio" name="radioOption" id="radioOption2" value="Csökkenő"
                                   onChange={orderAscendenceChange}
                            />
                            Csökkenő
                        </Label>
                    </FormGroup>
                    <br/>
                    <FormGroup>
                        <Label for="exampleSelect">
                            Rendezési érték:
                        </Label>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            onChange={orderChange}
                        >
                            <option>
                                Nincs
                            </option>
                            <option>
                                Címke név szerint
                            </option>
                            <option>
                                Katalógusszám szerint
                            </option>
                            <option>
                                Megjelenési év szerint
                            </option>
                            <option>
                                Címkék száma szerint
                            </option>
                        </Input>
                    </FormGroup>
                </Form>

            </Collapse>
        </div>
    );
};

export default OrderingMenu;
