import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faHome, faImage, faSort} from "@fortawesome/free-solid-svg-icons";
import {Nav, NavItem, NavLink} from "reactstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";
import YearsMenu from "./YearsMenu";
import SearchMenu from "./SearchMenu";
import OrderingMenu from "./OrderingMenu";
import Footer from "../content/Footer";

const SideBar = ({isOpen}) => {

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    return (
        <div className={classNames("sidebar", {"is-open": isOpen})}>
            <div className="sidebar-header">
                <h3 onClick={scrollToTop} className="centered cursor-link">Menü</h3>
            </div>
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    <h4>Gyorskeresés</h4>
                    <NavItem>
                        <NavLink tag={Link} to={"/gyufacimkek"}>
                            <h5>
                                <FontAwesomeIcon icon={faHome} className="mr-2"/>
                                Gyufacímke lista</h5>
                        </NavLink>
                    </NavItem>
                    <div className={"bottom-line"}/>
                    <SearchMenu title="Keresés" icon={faCopy}/>
                    <div className={"bottom-line"}/>
                    <OrderingMenu title="Rendezés" icon={faSort}/>
                    <div className={"bottom-line"}/>
                    <YearsMenu title="Év szerinti bontás" icon={faImage} items={submenus}/>
                    <div className={"bottom-line"}/>
                </Nav>
            </div>
            <Footer/>
        </div>
    );
}

const submenus = [
    {
        title: " - 1960",
        evig: 1960
    },
    {
        title: "1961 - 1970",
        evtol: 1961,
        evig: 1970,
    },
    {
        title: "1971 - ",
        evtol: 1971,
    },
];

export default SideBar;
