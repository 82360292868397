import React from 'react';
import '../../App.css';
import kollazs from '../../resources/kollazs400.png';

function Home() {
    return (
        <div className="centered">
            <img src={kollazs} alt="Gyufacímkék"/>
        </div>
    );
}

export default Home;