import React, {createContext, useContext, useState} from "react";

const MatchboxContext = createContext(undefined);

export const MatchboxDataProvider = ({children}) => {
    const [matchboxList, setMatchboxList] = useState([]);
    const [filter, setFilter] = useState({evtol: undefined, evig: undefined, sorozat: '', katalogus: '', meret: ''});
    const [order, setOrder] = useState({type: undefined, ascending: true});
    const dataToLoad = 50;
    const [index, setIndex] = useState(1);

    const isAnyMatchboxLeirasContainsText = (items) => {
        return items.some((item) => {
            return item.cimkeSzovegLeiras != null && item.cimkeSzovegLeiras.toLowerCase().includes(filter.sorozat);
        })
    }

    const isAnyMatchboxSorszamContainsText = (items) => {
        return items.some((item) => {
            return item.cimkeSorszam != null && item.cimkeSorszam.toLowerCase().includes(filter.katalogus);
        })
    }

    const isAnyMatchboxMeretContainsText = (items) => {
        return items.some((item) => {
            return item.cimkeMeret != null && item.cimkeMeret.toLowerCase().includes(filter.meret);
        })
    }

    const sortSeries = (items) => {
        switch (order.type) {
            case 'cimkeNev':
                return (order.ascending ? [...items].sort((a, b) =>
                        ((a.cimkek.length === 1 ? (a.cimkek[0].cimkeNev ? a.cimkek[0].cimkeNev.trim() : '') : (a.sorozatNev ? a.sorozatNev.trim() : '')).localeCompare(
                                ((b.cimkek.length === 1 ? (b.cimkek[0].cimkeNev ? b.cimkek[0].cimkeNev.trim() : '') : (b.sorozatNev ? b.sorozatNev.trim() : ''))),
                                "hu", {ignorePunctuation: true, sensitivity: 'base'})
                        )) :
                    [...items].sort((a, b) =>
                        ((b.cimkek.length === 1 ? (b.cimkek[0].cimkeNev ? b.cimkek[0].cimkeNev.trim() : '') : (b.sorozatNev ? b.sorozatNev.trim() : '')).localeCompare(
                                ((a.cimkek.length === 1 ? (a.cimkek[0].cimkeNev ? a.cimkek[0].cimkeNev.trim() : '') : (a.sorozatNev ? a.sorozatNev.trim() : ''))),
                                "hu", {ignorePunctuation: true, sensitivity: 'base'})
                        )));
            case 'katalogusszam':
                return (order.ascending ? [...items].sort((a, b) =>
                            (a.seriesId < b.seriesId ? -1 : 1)) :
                        [...items].sort((a, b) =>
                            (a.seriesId > b.seriesId ? -1 : 1))
                );
            case 'megjelenes':
                return (order.ascending ? [...items].sort((a, b) =>
                            (a.megjelenesiEvtol < b.megjelenesiEvtol ? -1 : 1)) :
                        [...items].sort((a, b) =>
                            (a.megjelenesiEvig > b.megjelenesiEvig ? -1 : 1))
                );
            case 'cimkeSzam' :
                return (order.ascending ? [...items].sort((a, b) =>
                            (a.cimkek.length < b.cimkek.length ? -1 : 1)) :
                        [...items].sort((a, b) =>
                            (a.cimkek.length > b.cimkek.length ? -1 : 1))
                );
            default:
                return items;
        }
    }

    const filteredData = sortSeries(matchboxList.filter((item) => {
        return (
            (filter.sorozat === '' ||
                ((item.cimkek.length === 1 && item.cimkek[0].cimkeNev != null && item.cimkek[0].cimkeNev.toLowerCase().includes(filter.sorozat)) ||
                    (item.cimkek.length !== 1 && (item.sorozatNev.toLowerCase().includes(filter.sorozat) || isAnyMatchboxLeirasContainsText(item.cimkek))))) &&
            (filter.evtol === '' ||
                filter.evtol === undefined || item.megjelenesiEvtol >= parseInt(filter.evtol)) &&
            (filter.evig === '' ||
                filter.evig === undefined || item.megjelenesiEvig <= parseInt(filter.evig)) &&
            (filter.katalogus === '' ||
                ((item.cimkek.length === 1 && item.cimkek[0].cimkeSorszam.toLowerCase().includes(filter.katalogus)) ||
                    (item.cimkek.length !== 1 && (item.cimkeSorszam.toLowerCase().includes(filter.katalogus) || isAnyMatchboxSorszamContainsText(item.cimkek))))) &&
            (filter.meret === '' ||
                ((item.cimkek.length === 1 && item.cimkek[0].cimkeMeret != null && item.cimkek[0].cimkeMeret.toLowerCase().includes(filter.meret)) ||
                    (item.cimkek.length !== 1 && isAnyMatchboxMeretContainsText(item.cimkek))))
        );
    }));

    const seriesCount = matchboxList.length;
    const shownData = filteredData.slice(0, index * dataToLoad);
    const hasMore = filteredData.length > shownData.length;

    return (
        <MatchboxContext.Provider value={{
            matchboxList,
            setMatchboxList,
            filteredData,
            filter,
            setFilter,
            shownData,
            hasMore,
            index,
            setIndex,
            order,
            setOrder,
            seriesCount
        }}>
            {children}
        </MatchboxContext.Provider>
    );
};

export const useMatchboxContext = () => {
    return useContext(MatchboxContext);
};