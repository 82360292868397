import React, {useState} from "react";
import {HashRouter as Router} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import "./App.css";
import {MatchboxDataProvider} from "./components/cimkek/MatchboxDataContext";

function App() {
    const [sidebarIsOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarIsOpen);
    }

    return (
        <Router>
            <MatchboxDataProvider>
                <div className="App wrapper">
                    <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen}/>
                    <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen}/>
                </div>
            </MatchboxDataProvider>
        </Router>
    );
}

export default App;
