import React, {Suspense} from 'react';
import '../../App.css';
import {useMatchboxContext} from "./MatchboxDataContext";
import {tryRequire} from "../../ImageLoader";
import InfiniteScroll from "react-infinite-scroller";
import {Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Col, Container, Row} from "reactstrap";
import {FaImage, FaImages} from "react-icons/fa6";
import classNames from "classnames";

function Gyufacimkek() {
    const {filteredData, shownData, hasMore, setIndex} = useMatchboxContext();

    const fetchMoreData = (page) => {
        setIndex((prevIndex) => prevIndex + 1);
    }

    const openSerieOrLabelInWindow = (sorozat, isNotSerie) => {
        let destination = '/#/sorozat/' + sorozat.seriesId;
        if (isNotSerie) {
            destination += '/cimke/' + sorozat.cimkek[0].labelId
        }
        window.open(destination, '_blank')
    }

    return (
        <div>
            <InfiniteScroll
                pageStart={0}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
            >
                <Container fluid>
                    <h4 style={{borderBottom: "solid 1px grey", marginBottom: "20px"}}>
                        {"Találatok száma: " + filteredData.length + " (megjelenítve: " + shownData.length + ")"}
                    </h4>
                    <Row>
                        {shownData &&
                            shownData.map((entry, index) => (
                                <Col key={index} xs="auto" className="col-style">
                                    <Card key={index}
                                          className={classNames("box-shadow card-fixed-size", {"sorozat-card": entry.cimkek.length !== 1})}
                                          onClick={() => openSerieOrLabelInWindow(entry, entry.cimkek.length === 1)}
                                    >
                                        <CardHeader>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                {(tryRequire(entry.cimkek[0].kep) &&
                                                    (<CardImg
                                                        alt="Címke kép"
                                                        src={tryRequire(entry.cimkek[0].kep)}
                                                        className="simple-image"
                                                    />)) || <div style={{textAlign: "center"}}>Nincs hozzá kép</div>}
                                            </Suspense>
                                        </CardHeader>
                                        <CardBody className="d-flex flex-column">
                                            <CardTitle tag="h5">
                                                {(entry.cimkek.length === 1 && (
                                                        <div>
                                                            {entry.cimkek[0].cimkeNev}
                                                            <h2 className="float-end">
                                                                <FaImage/>
                                                            </h2>
                                                        </div>
                                                    )) ||
                                                    <div>
                                                        {entry.sorozatNev}
                                                        <h2 className="float-end">
                                                            <FaImages/>
                                                        </h2>
                                                    </div>
                                                }
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                                style={{marginTop: -20}}
                                            >
                                                {entry.megjelenesiEvtol} - {entry.megjelenesiEvig}
                                            </CardSubtitle>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                {(entry.cimkek.length === 1 && (
                                                        <div>
                                                            <li><b>Katalógusszám:</b> {entry.cimkek[0].cimkeSorszam || '-'}
                                                            </li>
                                                            <li><b>Megjegyzés:</b> {entry.cimkek[0].megjegyzes || '-'} </li>
                                                            <li><b>Méret:</b> {entry.cimkek[0].cimkeMeret || '-'} </li>
                                                        </div>
                                                    )) ||
                                                    <div>
                                                        <li><b>Katalógusszám:</b> {entry.cimkeSorszam || '-'} </li>
                                                        <li><b>Címkék száma:</b> {entry.cimkek.length} </li>
                                                    </div>
                                                }
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </Container>
            </InfiniteScroll>
        </div>
    );
}

export default Gyufacimkek;