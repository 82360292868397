import React, {useEffect, useState} from 'react';
import '../../App.css';
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, ButtonGroup, Container, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {tryRequire} from "../../ImageLoader";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMatchboxContext} from "./MatchboxDataContext";
import {properties} from "../../properties";

const CimkeReszletek = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [sorozat, setSorozat] = useState(undefined);
    const [element, setElement] = useState(undefined);
    const {sorozatId, cimkeId} = useParams();
    const location = useLocation();
    const {seriesCount} = useMatchboxContext();

    const toggle = () => setModal(!modal);

    useEffect(() => {
        const setSorozatData = () => {
            axios.get(properties.baseURL + "/serie/" + sorozatId).then((response) => {
                setSorozat(response.data);
                setElement(response.data.cimkek.find(item => (item.labelId + '') === cimkeId));
            }).catch(error => {
                alert("Error Occurred while loading data:" + error);
            });
        }

        setSorozatData()
        window.scrollTo({top: 0, behavior: 'auto'});
    }, [sorozatId, cimkeId]);

    const navigateBack = () => {
        if (sorozat.cimkek.length !== 1) {
            navigate('/sorozat/' + sorozat.seriesId, {state: {posY: location.state?.posY}})
        } else {
            window.close();
        }
    }

    const currentLabelIndex = sorozat?.cimkek.indexOf(element);

    const navigateToElement = (stepCount) => {
        if (sorozat.cimkek.length === 1) {
            navigate('/sorozat/' + (parseInt(sorozatId) + stepCount))
        } else {
            navigate('/sorozat/' + sorozatId + '/cimke/' + sorozat.cimkek[currentLabelIndex + stepCount].labelId, {state: {posY: location.state?.posY}})
        }
    }

    //Nincs következő elem, ha csak egy elemű a sorozat (címkeként jelenik meg a főoldalon) és a sorozatok számát elértük,
    //vagy ha sorozaton belüli címkében vagyunk és az utolsó sorozat címkénél vagyunk
    const isNextDisabled = () => {
        return sorozat.cimkek.length === 1 ? (sorozatId === (seriesCount + "")) : (currentLabelIndex === sorozat.cimkek.length - 1);
    }

    //Az előzőhöz hasonlóan, annyi különbséggel, hogy most az a feltétel, hogy az első elemnél vagyunk-e
    const isPreviousDisabled = () => {
        return sorozat.cimkek.length === 1 ? (sorozatId === "1") : (currentLabelIndex === 0);
    }

    return (
        <div>
            {(sorozat !== undefined && element !== undefined) &&
                <Container fluid>
                    <div>
                        <Modal className="modal-content" isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>{element.cimkeNev}</ModalHeader>
                            <ModalBody>
                                <img
                                    src={tryRequire(element.kep)}
                                    className="modal-image"
                                    alt="Címke kép"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <div className="text">
                            <ButtonGroup style={{float: "right"}}>
                                <Button className="btn-lg btn-block mt-auto"
                                        onClick={() => navigateToElement(-1)}
                                        disabled={isPreviousDisabled()}>
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                </Button>
                                <Button className="btn-lg btn-block mt-auto"
                                        onClick={() => navigateToElement(1)}
                                        disabled={isNextDisabled()}>
                                    <FontAwesomeIcon icon={faArrowRight} className="mr-2"/>
                                </Button>
                            </ButtonGroup>
                            <h1>{element.cimkeNev}</h1>
                            <h3 className="text-muted">{element.megjelenesiEvtol} - {element.megjelenesiEvig}</h3>
                            <div className="image-container">
                                {tryRequire(element.kep) &&
                                    (<div className="padding-right padding-bottom">
                                        <img
                                            src={tryRequire(element.kep)}
                                            alt="Címke kép"
                                            className="image-detailed cursor-link image-bordered"
                                            onClick={toggle}
                                        />
                                    </div>)}
                                <ul className="list-unstyled mt-3 mb-4">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>Katalógusszám:</th>
                                            <td>{element.cimkeSorszam || '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Leírás:</th>
                                            <td>{element.cimkeSzovegLeiras || '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Megjegyzés:</th>
                                            <td>{element.megjegyzes || '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Méret:</th>
                                            <td>{element.cimkeMeret || '-'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {sorozat.colorCombinations.length > 0 && (
                        <div>
                            <h2>Színváltozatok:</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th width="15%">Színváltozat</th>
                                    <th width="40%">Színek</th>
                                    <th width="20%">Kis példányszám</th>
                                    <th width="25%">Megjegyzés</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sorozat.colorCombinations.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry.szinVariacio}</td>
                                            <td>{entry.cimkeSzine}</td>
                                            <td>{entry.kispeldanyszam ? "Igen" : "Nem"}</td>
                                            <td>{entry.megjegyzes}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {(window.opener || sorozat.cimkek.length !== 1) && (
                        <Button style={{margin: 10}} className="btn-lg btn-block mt-auto"
                                onClick={() => navigateBack()}>
                            {(sorozat.cimkek.length !== 1 && <div>Vissza</div>) || <div>Bezár</div>}
                        </Button>
                    )}
                </Container>
            }
        </div>
    );
}
export default CimkeReszletek;