import React, {Suspense, useEffect, useState} from 'react';
import '../../App.css';

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Row
} from "reactstrap";
import {tryRequire} from "../../ImageLoader";
import axios from "axios";
import {useMatchboxContext} from "./MatchboxDataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {properties} from "../../properties";

const Reszletek = () => {
    const navigate = useNavigate();
    const [sorozat, setSorozat] = useState(undefined);
    const {sorozatId} = useParams();
    const location = useLocation();
    const {filter, order, seriesCount} = useMatchboxContext();

    const sortLabels = (items) => {
        switch (order.type) {
            case 'cimkeNev':
                return (order.ascending ? [...items].sort((a, b) =>
                        (a.cimkeNev ? a.cimkeNev.trim() : '').localeCompare(
                            (b.cimkeNev ? b.cimkeNev.trim() : ''),
                            "hu", {ignorePunctuation: true, sensitivity: 'base'})
                    ) :
                    [...items].sort((a, b) =>
                        (b.cimkeNev ? b.cimkeNev.trim() : '').localeCompare(
                            (a.cimkeNev ? a.cimkeNev.trim() : ''),
                            "hu", {ignorePunctuation: true, sensitivity: 'base'})
                    ));
            case 'katalogusszam':
                return (order.ascending ? [...items].sort((a, b) =>
                            (a.labelId < b.labelId ? -1 : 1)) :
                        [...items].sort((a, b) =>
                            (a.labelId > b.labelId ? -1 : 1))
                );
            case 'megjelenes':
                return (order.ascending ? [...items].sort((a, b) =>
                            (a.megjelenesiEvtol < b.megjelenesiEvtol ? -1 : 1)) :
                        [...items].sort((a, b) =>
                            (a.megjelenesiEvig > b.megjelenesiEvig ? -1 : 1))
                );
            case 'cimkeSzam' :
            default:
                return items;
        }
    }

    const filterLabels = list =>
        list.filter((item) =>
            ((filter.sorozat === '' ||
                    (item.cimkeNev != null && item.cimkeNev.toLowerCase().includes(filter.sorozat)) ||
                    (item.cimkeSzovegLeiras != null && item.cimkeSzovegLeiras.toLowerCase().includes(filter.sorozat))) &&
                (filter.evtol === '' ||
                    filter.evtol === undefined || item.megjelenesiEvtol >= parseInt(filter.evtol)) &&
                (filter.evig === '' ||
                    filter.evig === undefined || item.megjelenesiEvig <= parseInt(filter.evig)) &&
                (filter.katalogus === '' ||
                    (item.cimkeSorszam != null && item.cimkeSorszam.toLowerCase().includes(filter.katalogus))) &&
                (filter.meret === '' ||
                    (item.cimkeMeret != null && item.cimkeMeret.toLowerCase().includes(filter.meret)))
            ));

    useEffect(() => {
        const setSorozatData = () => {
            axios.get(properties.baseURL + "/serie/" + sorozatId).then((response) => {
                const data = response.data;
                if (data.cimkek.length === 1) {
                    navigate('/sorozat/' + sorozatId + '/cimke/' + data.cimkek[0].labelId)
                } else {
                    setSorozat(response.data);
                }
            }).catch(error => {
                alert("Error Occurred while loading data:" + error);
            });
        }

        const goToLastPosition = () => {
            if (location.state?.posY >= 0) {
                setTimeout(() => {
                    window.scrollTo(0, location.state.posY);
                    window.history.replaceState({}, undefined);
                }, 20);
            }
        };

        setSorozatData()
        goToLastPosition()
    }, [navigate, sorozatId, location.state?.posY]);

    const navigateWithValues = (sorozat, element) => {
        navigate('/sorozat/' + sorozat.seriesId + '/cimke/' + element, {state: {posY: window.scrollY}})
    }

    const navigateToElement = (stepCount) => {
        navigate('/sorozat/' + (parseInt(sorozatId) + stepCount))
    }

    return (
        <div>
            {(sorozat != null) &&
                <Container fluid>
                    <ButtonGroup style={{float: "right"}}>
                        <Button className="btn-lg btn-block mt-auto"
                                onClick={() => navigateToElement(-1)}
                                disabled={sorozatId === "1"}>
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                        </Button>
                        <Button className="btn-lg btn-block mt-auto"
                                onClick={() => navigateToElement(1)}
                                disabled={sorozatId === (seriesCount + "")}>
                            <FontAwesomeIcon icon={faArrowRight} className="mr-2"/>
                        </Button>
                    </ButtonGroup>
                    <div>
                        <h1>{sorozat.sorozatNev}</h1>
                        <h3>{sorozat.megjelenesiEvtol} - {sorozat.megjelenesiEvig}</h3>
                        <h5>Katalógusszám: {sorozat.cimkeSorszam || '-'}</h5>
                        <h5>Címkék száma: {sorozat.cimkek.length}</h5>
                    </div>
                    {sorozat.colorCombinations.length > 0 && (
                        <div>
                            <h2>Színváltozatok:</h2>

                            <table>
                                <thead>
                                <tr>
                                    <th width="15%">Színváltozat</th>
                                    <th width="40%">Színek</th>
                                    <th width="20%">Kis példányszám</th>
                                    <th width="25%">Megjegyzés</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sorozat.colorCombinations.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry.szinVariacio}</td>
                                            <td>{entry.cimkeSzine}</td>
                                            <td>{entry.kispeldanyszam ? "Igen" : "Nem"}</td>
                                            <td>{entry.megjegyzes}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {(sorozat.cimkek.length !== 1) && (
                        <div>
                            <h2>Címkék:</h2>
                            <Row>
                                {filterLabels(sorozat.cimkek).map((entry, index) => (
                                    <Col key={index} xs="auto" className="col-style">

                                        <Card key={index} className="mb-3 box-shadow card-fixed-size"
                                              onClick={() => navigateWithValues(sorozat, entry.labelId)}
                                        >
                                            <CardHeader>
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    {(tryRequire(entry.kep) &&
                                                        (<CardImg
                                                                alt="Címke kép"
                                                                src={tryRequire(entry.kep)}
                                                                className="simple-image"
                                                            />
                                                        )) || <div style={{textAlign: "center"}}>Nincs hozzá kép</div>}
                                                </Suspense>
                                            </CardHeader>
                                            <CardBody className="d-flex flex-column">
                                                <CardTitle tag="h5">
                                                    {entry.cimkeNev}
                                                </CardTitle>
                                                <CardSubtitle
                                                    className="mb-2 text-muted"
                                                    tag="h6"
                                                >
                                                    {entry.megjelenesiEvtol} - {entry.megjelenesiEvig}
                                                </CardSubtitle>
                                                <ul className="list-unstyled mt-3 mb-4">
                                                    <li><b>Katalógusszám:</b> {entry.cimkeSorszam || '-'} </li>
                                                    <li><b>Leírás:</b> {entry.cimkeSzovegLeiras || '-'} </li>
                                                    <li><b>Megjegyzés:</b> {entry.megjegyzes || '-'} </li>
                                                    <li><b>Méret:</b> {entry.cimkeMeret || '-'} </li>
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    )}
                    {window.opener && (
                        <Button style={{margin: 10}} className="btn-lg btn-block mt-auto"
                                onClick={() => window.close()}>
                            Bezár
                        </Button>
                    )}
                </Container>
            }
        </div>
    );
}
export default Reszletek;