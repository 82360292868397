import React, {useState} from "react";
import classNames from "classnames";
import {Col, Collapse, NavItem, NavLink, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useMatchboxContext} from "../cimkek/MatchboxDataContext";

const YearsMenu = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed(!collapsed);
    const {icon, title, items} = props;
    const {filter, setFilter, setIndex} = useMatchboxContext();

    const setYearFilter = (addedFilter) => {
        setFilter({...filter, ...addedFilter})
        setIndex(0);
    };

    return (
        <div>
            <NavItem
                onClick={toggle}
                className={classNames({"menu-open": !collapsed})}
            >
                <h5><NavLink className="dropdown-toggle cursor-link">
                    <FontAwesomeIcon icon={icon} className="mr-2"/>
                    {title}
                </NavLink></h5>
            </NavItem>
            <Collapse
                isOpen={!collapsed}
                navbar
                className={classNames("items-menu", {"mb-1": !collapsed})}
            >
                {items.map((item, index) => (
                    <NavItem key={index} className="pl-4">
                        <NavLink tag={Link} to='/gyufacimkek' onClick={() => setYearFilter({
                            evig: item.evig ? item.evig : undefined,
                            evtol: item.evtol ? item.evtol : undefined
                        })}>
                            <Row>
                                <Col xs={3}>
                                    {item.evtol}
                                </Col>
                                <Col xs={2}>
                                    -
                                </Col>
                                <Col xs={2}>
                                    {item.evig}
                                </Col>
                            </Row>
                        </NavLink>
                    </NavItem>
                ))}
            </Collapse>
        </div>
    );
};

export default YearsMenu;
